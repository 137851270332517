import { COLOR_CODE, COLOR_ATTRIBUTE,BUTTON_HOVER } from '../../../utils/colorconstant'
import { COMMON } from '../../../utils/constant'
import { showToast } from '@/utils/showtoast';
import { MESSAGE,POSTION } from '../../../utils/constant';
// import { ToastContainer,toast } from 'react-toastify';
import { validation } from '../../../utils/validation';


const Button = (props) => {
    let primaryColor = props.value.defaultColor ?? COLOR_CODE.BLUE_600;
    let btnText = props.value.buttonText ?? COMMON.SUBMIT;
    let btnAction = props.value.btnActionType;
    let formData = props.value.formData;


    const handleButtonClick = () => {
        if (btnAction === COMMON.SUBMIT) {
            validation(formData);
        }
    };

    let style = '';
    switch(primaryColor) {
        case COLOR_CODE.BLUE_600:
          style = 'w-full px-6 py-2 text-white bg-[#1e336a] hover:bg-[#002E6E] transition duration-300 ease-in-out rounded w-full';
          break;
        case COLOR_CODE.RED_600:
          style = 'w-full px-6 py-2 text-white bg-[#1e336a] hover:bg-[#002E6E] transition duration-300 ease-in-out rounded w-full';
          break;
        default:
        style = 'w-full px-6 py-2 text-white bg-[#1e336a] hover:bg-[#002E6E] transition duration-300 ease-in-out rounded w-full';
      }

    return (
        <button
            className={style}
             onClick={handleButtonClick}
             >
            {btnText}
        </button>
    );
};

export default Button;
