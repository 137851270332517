import React, { useState, useRef, useEffect } from "react";
import Image from "next/image";
import { MESSAGE, POSTION, COMMON, ROLES, STATUS, ROUTE_STRING } from "../../utils/constant";
import {
  COLOR_CODE,
  COLOR_ATTRIBUTE,
  BUTTON_HOVER,
} from "../../utils/colorconstant";
import { showToast } from "../../utils/showtoast";
import Button from "@/components/common/buttons";
import logoimg from "../../public/assets/images/easycampuslogo.jpg";
import {
  validateEmail,
  validatePassword,
  validateMobileOtp,
  validateEmailOtp,
  validateEmailOrMobileOtp,
  validateConfirmPassword,
  validateNewPassword,
} from "@/utils/validation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  userLoginApi,
  otpValidationApi,
  sendMobileOtpApi,
  sendEmailOtpApi,
  forgotPasswordApi,
  verifyForgotPasswordOtpApi,
  getGuestLogDetails,
} from "@/components/action/easycampus";
import { useRouter } from "next/router";
import Loader from "@/components/common/loader";
import Link from "next/link";
import { BsEnvelope, BsFileLock, BsEyeSlash, BsEye } from "react-icons/bs";
import verifiedimg from "../../public/assets/images/check.svg";
import { capitalizeFirstLetter } from "@/utils/common";
import Cookies from "js-cookie";
// import { usePathname } from "next/navigation";
// import { useStateValue } from "@/components/common/context/statecontext";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const emailOrMobileInputRef = useRef(null);
  const [passwordType, setPasswordType] = useState("password");
  const router = useRouter();
  const [showLoader, setShowLoader] = useState(false);
  const [otpInputMobile, setOtpInputMobile] = useState("");
  const [otpInputEmail, setOtpInputEmail] = useState("");
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [otpTimerEmail, setOtpTimerEmail] = useState(60);
  const [otpTimerMobile, setOtpTimerMobile] = useState(60);
  const [otpVerificationError, setOtpVerificationError] = useState("");
  const [emailOtpError, setEmailOtpError] = useState("");
  const [mobileOtpError, setMobileOtpError] = useState("");
  const [showOtpVerifiedPopup, setShowOtpVerifiedPopup] = useState(false);
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [emailOrMobileError, setEmailOrMobileError] = useState("");
  const [emailOrMobileOtp, setEmailOrMobileOtp] = useState("");
  const [emailOrMobileOtpError, setEmailOrMobileOtpError] = useState("");
  const [showEmailOrMobileOtpInput, setShowEmailOrMobileOtpInput] =
    useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isEnabledMobileOrEmailInput, setIsEnabledMobileOrEmailInput] =
    useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");
  const [previousUrl, setPreviousUrl] = useState("");
  const [logOutAllDeviceModal, setLogOutAllDeviceModal] = useState(false);
  const [logOutAllDevice, setLogOutAllDevice] = useState("");
  // const { data } = useStateValue();

  useEffect(() => {
    const token = Cookies.get("x-auth-token");
    const loginData = Cookies.get("loginData");
    if (token && loginData) {
      const parsedLoginData = JSON.parse(loginData);
      sessionStorage.setItem("x-auth-token", token);
      sessionStorage.setItem("loginData", JSON.stringify(parsedLoginData));
      router.push("/dashboard");
    }
  }, [router]);

  useEffect(() => {
    // sessionStorage.clear();
    handleGestLogUserData();
    let previousUrl = sessionStorage.getItem("previousUrl");
    setPreviousUrl(sessionStorage.getItem("previousUrl"));
    console.log("previousUrl login", previousUrl);

    // sessionStorage.clear();

    // let previousUrl = router.query;
    // console.log("window.history.back()",window.history);

    let timerEmail;
    let timerMobile;
    if (showOtpPopup && otpTimerEmail > 0) {
      timerEmail = setTimeout(() => {
        setOtpTimerEmail((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (showOtpPopup && otpTimerMobile > 0) {
      timerMobile = setTimeout(() => {
        setOtpTimerMobile((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timerEmail);
      clearTimeout(timerMobile);
    };
  }, [
    showOtpPopup,
    otpTimerEmail,
    otpTimerMobile,
    setOtpTimerEmail,
    setOtpTimerMobile,
  ]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setEmailErrorMessage("");
    setPasswordErrorMessage("");
    if (!email) {
      setEmailErrorMessage(MESSAGE.EMAIL_IS_REQUIRED);
    } else if (email) {
      setEmailErrorMessage(validateEmail(email.trim()));
    }
    if (!password) {
      setPasswordErrorMessage(MESSAGE.PASSWORD_IS_REQUIRED);
    } else if (password) {
      setPasswordErrorMessage(validatePassword(password.trim()));
    }
    if (!email) {
      emailInputRef.current.focus();
      return;
    } else if (email) {
      if (validateEmail(email.trim())) {
        emailInputRef.current.focus();
        return;
      }
    }
    if (!password) {
      passwordInputRef.current.focus();
      return;
    } else if (password) {
      if (validatePassword(password.trim())) {
        passwordInputRef.current.focus();
        return;
      }
    }
    setShowLoader(true);
    try {
      const fcmToken = sessionStorage.getItem("fcmToken");

      console.log("fcmToken", fcmToken);

      let loginData;

      if (logOutAllDevice?.customData?.isLoggedInDeviceLimitExceed === true && logOutAllDeviceModal === true) {
        loginData = await userLoginApi({
          email,
          password,
          fcmToken,
          isLogOutFromAllDevice: true,
        });
      } else {
        loginData = await userLoginApi({ email, password, fcmToken });
      }

      // const loginData = await userLoginApi({ email, password, fcmToken });
      // console.log("loginData", loginData);

      if (loginData.data.hasError === false) {
        showToast(
          loginData.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          true
        );
        if (logOutAllDeviceModal === false) {
          setEmail(loginData.data.data.email);
          setMobile(loginData.data.data.mobile);
        }
        setLogOutAllDeviceModal(false);
        sessionStorage.setItem("loginData", JSON.stringify(loginData.data));
        const rememberMe = document.getElementById("checkbox").checked;
        if (rememberMe) {
          Cookies.set("x-auth-token", loginData.data.data.token, {
            expires: 7,
          }); // Expires in 7 days
          Cookies.set("loginData", JSON.stringify(loginData.data), {
            expires: 7,
          });
        }
        if (typeof window !== "undefined") {
          sessionStorage.setItem("x-auth-token", loginData.data.data.token);
        }

        if (loginData.data.data.isFirstTimeLogin === true) {
          router.push("/changepassword");
        }

        if (
          loginData.data.data.isFirstTimeLogin === false &&
          loginData.data.data.isEmailVerifed === false &&
          loginData.data.data.isMobileVerifed === false
        ) {
          setShowOtpPopup(true);
          handleEmailResendOtp(loginData.data.data.email);
          handleMobileResendOtp(loginData.data.data.mobile);
        }

        if (loginData.data.data.userType === ROLES.SUPER_ADMIN) {
          if (previousUrl) {
            router.push(previousUrl);
          } else {
            router.push("/dashboard");
          }
        }

        if (loginData.data.data.userType === ROLES.INTERVIEWER) {
          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.APPROVED &&
            loginData.data.data.isPaymentStatus === true &&
            loginData.data.data.isRenewStatus === true &&
            loginData.data.data.isEmailVerifed === true &&
            loginData.data.data.isMobileVerifed === true
          ) {
            router.push("/dashboard");
          }
        }
        if (loginData.data.data.userType === ROLES.CAMPUS) {
          if (
            loginData.data.data.isProfileUpdated === false &&
            loginData.data.data.isReveiwStatus === STATUS.INITIAL &&
            loginData.data.data.isPaymentStatus === false &&
            loginData.data.data.isRenewStatus === false &&
            loginData.data.data.isInitialReveiwStatus === STATUS.APPROVED
          ) {
            router.push("/campus/campusprofileupdate");
          }

          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.PENDING &&
            loginData.data.data.isPaymentStatus === false &&
            loginData.data.data.isRenewStatus === false &&
            loginData.data.data.isInitialReveiwStatus === STATUS.APPROVED
          ) {
            router.push("/campus/campusprofileupdate");
          }

          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.PENDING &&
            loginData.data.data.isPaymentStatus === true &&
            loginData.data.data.isRenewStatus === true
          ) {
            router.push("/profileunderreview");
          }
          if (
            loginData.data.data.isReveiwStatus === STATUS.INITIAL &&
            loginData.data.data.isInitialReveiwStatus === STATUS.PENDING
          ) {
            router.push("/profileunderreview");
          }
          if (
            loginData.data.data.isRenewStatus === false &&
            loginData.data.data.isEmailVerifed === true &&
            loginData.data.data.isMobileVerifed === true &&
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.APPROVED &&
            loginData.data.data.isInitialReveiwStatus === STATUS.APPROVED
          ) {
            router.push("/campus/campusprofileupdate");
          }

          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isEmailVerifed === true &&
            loginData.data.data.isMobileVerifed === true &&
            loginData.data.data.isReveiwStatus === STATUS.REJECTED &&
            loginData.data.data.isPaymentStatus === true &&
            loginData.data.data.isRenewStatus === true &&
            loginData.data.data.isInitialReveiwStatus === STATUS.APPROVED
          ) {
            router.push("/campus/campusprofileupdate");
          }

          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.APPROVED &&
            loginData.data.data.isPaymentStatus === true &&
            loginData.data.data.isRenewStatus === true &&
            loginData.data.data.isEmailVerifed === true &&
            loginData.data.data.isMobileVerifed === true
          ) {
            router.push("/dashboard");
          }
        }

        if (loginData.data.data.userType === ROLES.CORPORATE) {
          if (
            loginData.data.data.isProfileUpdated === false &&
            loginData.data.data.isReveiwStatus === STATUS.INITIAL &&
            loginData.data.data.isPaymentStatus === false &&
            loginData.data.data.isRenewStatus === false &&
            loginData.data.data.isInitialReveiwStatus === STATUS.APPROVED
          ) {
            router.push("/corporates/corporateprofileupdate");
          }

          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.PENDING &&
            loginData.data.data.isPaymentStatus === false &&
            loginData.data.data.isRenewStatus === false &&
            loginData.data.data.isInitialReveiwStatus === STATUS.APPROVED
          ) {
            router.push("/corporates/corporateprofileupdate");
          }

          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.PENDING &&
            loginData.data.data.isPaymentStatus === true &&
            loginData.data.data.isRenewStatus === true
          ) {
            router.push("/profileunderreview");
          }
          if (
            loginData.data.data.isReveiwStatus === STATUS.INITIAL &&
            loginData.data.data.isInitialReveiwStatus === STATUS.PENDING
          ) {
            router.push("/profileunderreview");
          }
          if (
            loginData.data.data.isRenewStatus === false &&
            loginData.data.data.isEmailVerifed === true &&
            loginData.data.data.isMobileVerifed === true &&
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.APPROVED &&
            loginData.data.data.isInitialReveiwStatus === STATUS.APPROVED
          ) {
            router.push("/corporates/corporateprofileupdate");
          }

          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isEmailVerifed === true &&
            loginData.data.data.isMobileVerifed === true &&
            loginData.data.data.isReveiwStatus === STATUS.REJECTED &&
            loginData.data.data.isPaymentStatus === true &&
            loginData.data.data.isRenewStatus === true &&
            loginData.data.data.isInitialReveiwStatus === STATUS.APPROVED
          ) {
            router.push("/corporates/corporateprofileupdate");
          }

          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.APPROVED &&
            loginData.data.data.isPaymentStatus === true &&
            loginData.data.data.isRenewStatus === true &&
            loginData.data.data.isEmailVerifed === true &&
            loginData.data.data.isMobileVerifed === true
          ) {
            router.push("/dashboard");
          }
        }

        if (
          loginData.data.data.userType === ROLES.ON_STUDENT ||
          loginData.data.data.userType === ROLES.OFF_STUDENT
        ) {
          if (
            loginData.data.data.isProfileUpdated === false &&
            loginData.data.data.isReveiwStatus === STATUS.INITIAL &&
            loginData.data.data.isPaymentStatus === false &&
            loginData.data.data.isRenewStatus === false &&
            loginData.data.data.isInitialReveiwStatus === STATUS.APPROVED
          ) {
            router.push("/profileupdate/student");
          }

          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.PENDING &&
            loginData.data.data.isPaymentStatus === false &&
            loginData.data.data.isRenewStatus === false &&
            loginData.data.data.isInitialReveiwStatus === STATUS.APPROVED
          ) {
            router.push("/profileupdate/student");
          }

          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.PENDING &&
            loginData.data.data.isPaymentStatus === true &&
            loginData.data.data.isRenewStatus === true
          ) {
            router.push("/profileunderreview");
          }
          if (
            loginData.data.data.isReveiwStatus === STATUS.INITIAL &&
            loginData.data.data.isInitialReveiwStatus === STATUS.PENDING
          ) {
            router.push("/profileunderreview");
          }

          if (
            loginData.data.data.isRenewStatus === false &&
            loginData.data.data.isEmailVerifed === true &&
            loginData.data.data.isMobileVerifed === true &&
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.APPROVED &&
            loginData.data.data.isInitialReveiwStatus === STATUS.APPROVED
          ) {
            router.push("/profileupdate/student");
          }

          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isEmailVerifed === true &&
            loginData.data.data.isMobileVerifed === true &&
            loginData.data.data.isReveiwStatus === STATUS.REJECTED &&
            loginData.data.data.isPaymentStatus === true &&
            loginData.data.data.isRenewStatus === true &&
            loginData.data.data.isInitialReveiwStatus === STATUS.APPROVED
          ) {
            router.push("/profileupdate/student");
          }

          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.APPROVED &&
            loginData.data.data.isPaymentStatus === false &&
            loginData.data.data.isRenewStatus === false &&
            loginData.data.data.isEmailVerifed === true &&
            loginData.data.data.isMobileVerifed === true
          ) {
            router.push("/profileupdate/student");
          }
          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.INITIAL &&
            loginData.data.data.isPaymentStatus === false &&
            loginData.data.data.isRenewStatus === false &&
            loginData.data.data.isInitialReveiwStatus === STATUS.APPROVED
          ) {
            router.push("/profileupdate/student");
          }

          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.APPROVED &&
            loginData.data.data.isPaymentStatus === true &&
            loginData.data.data.isRenewStatus === true &&
            loginData.data.data.isEmailVerifed === true &&
            loginData.data.data.isMobileVerifed === true
          ) {
            router.push("/dashboard");
          }
          // console.log("loginData.data.data.userType",loginData.data.data.userType);
        }

        if (loginData.data.data.userType === ROLES.BLOG_MANAGEMENT) {
          if (
            loginData.data.data.isProfileUpdated === true &&
            loginData.data.data.isReveiwStatus === STATUS.APPROVED &&
            loginData.data.data.isPaymentStatus === true &&
            loginData.data.data.isRenewStatus === true &&
            loginData.data.data.isEmailVerifed === true &&
            loginData.data.data.isMobileVerifed === true
          ) {
            router.push(`/${ROUTE_STRING.BLOGS}`);
          }
        }

      } else {
        showToast(
          loginData.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );

        console.log(
          "loginData.data.data",
          loginData.data.customData.isLoggedInDeviceLimitExceed
        );

        if (loginData?.data?.customData?.isLoggedInDeviceLimitExceed === true) {
          setLogOutAllDeviceModal(true);
          setLogOutAllDevice(loginData.data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleForgotPassword = (e) => {
    setShowForgotPasswordPopup(!showForgotPasswordPopup);
  };

  const handleNewPasswordChange = (e) => {
    if (e.target.value.trim() === "") {
      setNewPasswordError(MESSAGE.NEW_PASSWORD_REQUIRED);
    } else {
      setNewPasswordError(validateNewPassword(e.target.value.trim()));
    }
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    if (e.target.value.trim() === "") {
      setConfirmNewPasswordError(MESSAGE.CONFIRM_PASSWORD);
    } else {
      if (newPassword === e.target.value.trim()) {
        setConfirmNewPasswordError("");
      } else {
        setConfirmNewPasswordError(MESSAGE.CONFIRM_PASSWORD_MISSMATCH);
      }
    }
    setConfirmNewPassword(e.target.value);
  };

  const handleEmailMobileOtpChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    setEmailOrMobileOtp(numericValue.slice(0, 6));
    setEmailOrMobileOtpError(validateEmailOrMobileOtp(numericValue.trim()));
  };

  const passwordShowHide = (e) => {
    if (passwordType == "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const handleEmailChange = (e) => {
    if (e.target.value.trim() === "") {
      setEmailErrorMessage(MESSAGE.EMAIL_IS_REQUIRED);
      emailInputRef.current.focus();
    } else {
      setEmailErrorMessage(validateEmail(e.target.value.trim()));
      emailInputRef.current.focus();
    }
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    if (e.target.value.trim() === "") {
      setPasswordErrorMessage(MESSAGE.PASSWORD_IS_REQUIRED);
      passwordInputRef.current.focus();
    } else {
      setPasswordErrorMessage(validatePassword(e.target.value.trim()));
      passwordInputRef.current.focus();
    }
    setPassword(e.target.value);
  };

  const handleOtpMobileChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    setOtpInputMobile(numericValue.slice(0, 6));
    setMobileOtpError(validateMobileOtp(numericValue.trim()));
  };

  const handleOtpEmailChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    setOtpInputEmail(numericValue.slice(0, 6));
    setEmailOtpError(validateEmailOtp(numericValue.trim()));
  };

  const handleEmailOrMobile = (e) => {
    let inputValue = e.target.value;
    let inputValueDataType = typeof inputValue;
    let isValid = false;

    if (inputValueDataType === "string") {
      if (/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(inputValue)) {
        isValid = true;
        setEmailOrMobileError("");
        // handleEmailResendOtp(inputValue.trim());
      } else if (/^[6789][0-9]{9}$/.test(inputValue)) {
        isValid = true;
        setEmailOrMobileError("");
        // handleMobileResendOtp(inputValue.trim())
      }
    }

    if (!isValid) {
      setEmailOrMobileError(MESSAGE.PLEASE_ENTER_VALID_EMAIL_OR_MOBILE);
    }

    setEmailOrMobile(inputValue);
  };

  const sendOtpForgotPassword = async (reqBody) => {
    try {
      setShowLoader(true);
      const forgotPasswordResponse = await forgotPasswordApi(reqBody);
      if (forgotPasswordResponse.data.hasError == false) {
        showToast(
          forgotPasswordResponse.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          true
        );

        if (emailOrMobile) setIsEnabledMobileOrEmailInput(true);
      }
      if (forgotPasswordResponse.data.hasError == true) {
        showToast(
          forgotPasswordResponse.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          false
        );
        if (emailOrMobile) setIsEnabledMobileOrEmailInput(false);
      }

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error(error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, true);
    }
  };

  const veryfyOtpForgotPassword = async (reqBody) => {
    try {
      setShowLoader(true);
      const veryfyPasswordResponse = await verifyForgotPasswordOtpApi(reqBody);
      if (veryfyPasswordResponse.data.hasError == false) {
        showToast(
          veryfyPasswordResponse.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          true
        );

        setShowForgotPasswordPopup(false);
        setIsEnabledMobileOrEmailInput(false);
        setEmailOrMobile("");
        setEmailOrMobileOtp("");
        setNewPassword("");
        setConfirmNewPassword("");

        // if(emailOrMobile) setIsEnabledMobileOrEmailInput(true)
      }
      if (veryfyPasswordResponse.data.hasError == true) {
        showToast(
          veryfyPasswordResponse.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          false
        );
        // if(emailOrMobile) setIsEnabledMobileOrEmailInput(false)
      }

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error(error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, true);
    }
  };

  const handleOtpSubmit = async () => {
    try {
      if (!otpInputMobile) {
        setMobileOtpError(MESSAGE.MOBILE_OTP_IS_REQUIRED);
      } else if (otpInputMobile) {
        setMobileOtpError(validateMobileOtp(otpInputMobile.trim()));
      }

      if (!otpInputEmail) {
        setEmailOtpError(MESSAGE.EMAIL_OTP_IS_REQUIRED);
      } else if (otpInputEmail) {
        setEmailOtpError(validateMobileOtp(otpInputEmail.trim()));
      }

      if (otpInputEmail && otpInputMobile) {
        const reqBody = {
          mobile: mobile,
          mobileOtp: otpInputMobile,
          email: email,
          emailOtp: otpInputEmail,
        };
        setShowLoader(true);
        const otpVarifyResponse = await otpValidationApi(reqBody);
        if (otpVarifyResponse.data.hasError == false) {
          setShowLoader(false);
          setShowOtpPopup(false);
          setShowOtpVerifiedPopup(true);
          showToast(
            otpVarifyResponse.data.message,
            POSTION.TOP_RIGHT,
            COLOR_CODE.BLUE_600,
            true
          );
        } else {
          showToast(
            otpVarifyResponse.data.message,
            POSTION.TOP_RIGHT,
            COLOR_CODE.RED_600,
            false
          );
          setShowOtpPopup(true);
        }
      }
      setShowOtpPopup(true);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error(error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, true);
    }
  };

  const handleEmailORMobileOtpSubmit = async () => {
    try {
      if (!emailOrMobile) {
        setEmailOrMobileError(MESSAGE.EMAIL_OR_MOBILE_IS_REQURED);
      } else {
        if (isEnabledMobileOrEmailInput === false) {
          let inputValueDataType = typeof emailOrMobile;
          let isValid = false;

          if (inputValueDataType === "string") {
            if (/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(emailOrMobile)) {
              isValid = true;
              setEmailOrMobileError("");
              // handleEmailResendOtp(emailOrMobile.trim());
              let reqBody = { inputValue: emailOrMobile.trim() };
              sendOtpForgotPassword(reqBody);
            } else if (/^[6789][0-9]{9}$/.test(emailOrMobile)) {
              isValid = true;
              setEmailOrMobileError("");
              // handleMobileResendOtp(emailOrMobile.trim())

              let reqBody = { inputValue: emailOrMobile.trim() };
              sendOtpForgotPassword(reqBody);
            }
          }

          if (!isValid) {
            setEmailOrMobileError(MESSAGE.PLEASE_ENTER_VALID_EMAIL_OR_MOBILE);
          }

          // if (!emailOrMobile) {
          //   emailOrMobileInputRef.current.focus();
          // }
        } else if (isEnabledMobileOrEmailInput === true) {
          if (!emailOrMobile) {
            setEmailOrMobileError(MESSAGE.EMAIL_OR_MOBILE_IS_REQURED);
          }

          if (!emailOrMobileOtp) {
            setEmailOrMobileOtpError(MESSAGE.OTP_IS_REQUIRED);
          }

          if (!newPassword) {
            setNewPasswordError(MESSAGE.NEW_PASSWORD_REQUIRED);
          } else if (newPassword) {
            setNewPasswordError(validateNewPassword(newPassword.trim()));
          }

          if (!confirmNewPassword) {
            setConfirmNewPasswordError(MESSAGE.CONFIRM_PASSWORD);
          } else if (confirmNewPassword) {
            if (newPassword !== confirmNewPassword) {
              setConfirmNewPasswordError(MESSAGE.CONFIRM_PASSWORD_MISSMATCH);
            }
          }

          if (
            emailOrMobile &&
            emailOrMobileOtp &&
            newPassword &&
            confirmNewPassword
          ) {
            let reqBody = {
              inputValue: emailOrMobile.trim(),
              userOtp: emailOrMobileOtp.trim(),
              newPassword: newPassword.trim(),
              confirmNewPassword: confirmNewPassword.trim(),
            };
            veryfyOtpForgotPassword(reqBody);
          }
        }
      }
    } catch (error) {
      setShowLoader(false);
      console.error(error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, true);
    }
  };

  const handleEmailResendOtp = async (email) => {
    try {
      setShowLoader(true);
      const sendEmailOtpres = await sendEmailOtpApi({ email });
      if (sendEmailOtpres.data.hasError === false) {
        showToast(
          sendEmailOtpres.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          true
        );
        setOtpTimerEmail(60);
        setShowLoader(false);
      } else {
        showToast(
          sendEmailOtpres.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
        setShowOtpPopup(true);
        setShowLoader(false);
      }
    } catch (error) {
      console.error(error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
      setShowLoader(false);
    }
  };

  const handleMobileResendOtp = async (mobile) => {
    try {
      setShowLoader(true);
      const sendMobileOtpres = await sendMobileOtpApi({ mobile });
      if (sendMobileOtpres.data.hasError === false) {
        showToast(
          sendMobileOtpres.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          true
        );
        setOtpTimerMobile(60);
        setShowLoader(false);
      } else {
        showToast(
          sendMobileOtpres.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
        setShowOtpPopup(true);
        setShowLoader(false);
      }
    } catch (error) {
      console.error(error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
      setShowLoader(false);
    }
  };

  const handleGestLogUserData = async () => {
    try {
      const sendEmailOtpres = await getGuestLogDetails();
      return;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className={`loginContainer`}>
        <div className="flex justify-center items-center h-screen ">
          {showLoader && <Loader />}
          <form
            className="w-96 p-6 shadow-lg bg-white rounded-md absolute zoomSize"
            onSubmit={handleLoginSubmit}
          >
            <div className="items-center w-[70%] ml-11">
              <Image src={logoimg} alt="logoimg" />
            </div>
            <h1 className="text-xl block text-center font-semibold mt-3 text-[#1e336a]">
              Login to Your Account
            </h1>

            <div className="relative">
              <BsEnvelope
                className="text-gray-400"
                style={{
                  position: "absolute",
                  top: 28,
                  left: 12,
                }}
              />
              <input
                ref={emailInputRef}
                className="bordercolor mt-3 border w-full text-base px-8 py-2 focus:outline-none focus:ring-0 focus:border-gray-600 rounded-md"
                type="text"
                value={email}
                autoComplete="off"
                onChange={handleEmailChange}
                placeholder="Email *"
              />
            </div>
            <div id="emailErrorMessage" className="text-red-500 text-sm">
              {emailErrorMessage}
            </div>

            <div className="relative">
              <BsFileLock
                className="text-gray-400"
                style={{
                  position: "absolute",
                  top: 34,
                  left: 12,
                }}
              />
              {passwordType === "password" ? (
                <>
                  <BsEyeSlash
                    className="text-gray-400 cursor-pointer"
                    onClick={passwordShowHide}
                    style={{
                      position: "absolute",
                      top: 34,
                      left: 310,
                    }}
                  />
                </>
              ) : (
                <>
                  <BsEye
                    className="text-gray-400 cursor-pointer"
                    onClick={passwordShowHide}
                    style={{
                      position: "absolute",
                      top: 34,
                      left: 310,
                    }}
                  />
                </>
              )}

              <input
                ref={passwordInputRef}
                className="mt-5 bordercolor border w-full text-base px-8 py-2 focus:outline-none focus:ring-0 focus:border-gray-600 rounded-md"
                type={passwordType}
                value={password}
                placeholder="Password *"
                autoComplete="off"
                onChange={handlePasswordChange}
              />
            </div>
            <div id="passwordErrorMessage" className="text-red-500 text-sm">
              {passwordErrorMessage}
            </div>
            <div className="mt-1 flex items-center">
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox"
                className="ml-3 rounded w-3 h-3 focus:outline-none focus:ring-0 cursor-pointer"
              />
              <span className="ml-1 text-xs text-gray-400">Remember Me</span>
              <span
                className="ml-auto text-xs cursor-pointer"
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </span>
            </div>
            <div className="mt-5 flex justify-center">
              <Button
                value={{
                  buttonText: COMMON.LOGIN,
                  btnActionType: COMMON.SUBMIT,
                }}
              />
            </div>
            <div className="mt-1 flex gap-2">
              <p>Don't have an account?</p>
              <Link href="/registration" className="text-[#00BAF1]">
                Sign Up
              </Link>
            </div>
            <div className="mt-1 flex gap-2">
              <Link href="https://easycampus.ai">
                <p className="text-[#0000ff]  underline">Click here</p>
              </Link>
              <p>to go to our website.</p>
            </div>
          </form>
          {showOtpPopup && (
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                  className="fixed inset-0 transition-opacity"
                  aria-hidden="true"
                >
                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                {showLoader && <Loader />}
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white p-5 flex flex-col items-center">
                    <div className="items-center w-[40%]">
                      <Image src={logoimg} alt="logoimg" />
                    </div>
                    <h1 className="flex justify-center text-2xl font-semibold mb-1 text-[#1e336a]">
                      OTP VERIFICATION
                    </h1>
                    <div className="w-[40%] text-[10px]">
                      We Will send you a one time Password on your{" "}
                      <strong>Mobile Number & Email</strong>
                    </div>
                    <div className="mb-2 w-full">
                      <input
                        type="text"
                        placeholder="Enter Mobile OTP"
                        value={otpInputMobile}
                        onChange={handleOtpMobileChange}
                        className="mt-2 border w-100 text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-[#1e336a] rounded-md w-[100%]"
                      />
                      <div className="text-red-500">
                        {otpVerificationError}
                        {mobileOtpError}
                      </div>

                      <div className="flex items-center justify-between mt-2">
                        <p className="text-gray-500">
                          {otpTimerMobile} seconds
                        </p>
                        <button
                          disabled={otpTimerMobile > 0}
                          onClick={handleMobileResendOtp}
                          className={`text-[#1e336a] hover:underline focus:outline-none ${otpTimerMobile > 0 ? "cursor-not-allowed" : ""
                            }`}
                        >
                          Resend OTP
                        </button>
                      </div>
                    </div>
                    <div className="mb-2 w-full">
                      <input
                        type="text"
                        placeholder="Enter Email OTP"
                        value={otpInputEmail}
                        onChange={handleOtpEmailChange}
                        className="'mt-2 border w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-[#1e336a] rounded-md"
                      />
                      <div className="text-red-500">
                        {otpVerificationError}
                        {emailOtpError}
                      </div>
                      <div className="flex items-center justify-between mt-2">
                        <p className="text-gray-500">{otpTimerEmail} seconds</p>
                        <button
                          onClick={handleEmailResendOtp}
                          disabled={otpTimerEmail > 0}
                          className={`text-[#1e336a] hover:underline focus:outline-none ${otpTimerEmail > 0 ? "cursor-not-allowed" : ""
                            }`}
                        >
                          Resend OTP
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <button
                        onClick={handleOtpSubmit}
                        className="w-full px-6 py-2 bg-[#1e336a] text-white rounded-md hover:bg-[#1e336a] focus:outline-none"
                      >
                        Submit
                      </button>

                      <button
                        onClick={() => setShowOtpPopup(false)}
                        className="absolute top-2 right-2 text-gray-500 cursor-pointer"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showOtpVerifiedPopup && (
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="fixed inset-0 z-50 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                  >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>
                  {showLoader && <Loader />}

                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>

                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white p-5 flex flex-col items-center">
                      <div className="items-center w-[40%]">
                        <Image src={logoimg} alt="logoimg" />
                      </div>

                      <Image
                        src={verifiedimg}
                        alt="verified"
                        className="w-[40%]"
                      />
                      <h1 className="text-xl block text-center font-semibold mt-3 text-[#1e336a] mb-3">
                        Thank you for submitting the form!
                      </h1>
                      <div className="w-[70%] text-[10px]">
                        We have received your information, and our team is now reviewing your data. This process ensures that everything is in order before your account can be activated.
                        Please allow us some time to complete the review, and we will notify you once your account is fully active. If you have any questions or need further assistance, feel free to reach out to our support team.
                      </div>

                      <div className="mt-5 px-6 py-2 bg-[#1e336a] text-white rounded-md hover:bg-[#002E6E] focus:outline-none text-center">
                        <Link href="/loginscreen" className="text-[#1e336a]">
                          <button className="w-full px-6 py-2 bg-[#1e336a] text-white rounded-md hover:bg-[#002E6E] focus:outline-none">
                            {COMMON.BACK_TO_LOGIN}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showForgotPasswordPopup && (
            <>
              <div className="fixed inset-0 z-50 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                  >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>
                  {showLoader && <Loader />}

                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>

                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white p-5 flex flex-col items-center">
                      <button
                        onClick={handleForgotPassword}
                        type="button"
                        className="crossicon"
                        data-modal-hide="default-modal"
                      >
                        <svg
                          className="crossiconSvg"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                      </button>

                      <div className="bg-white p-5 flex flex-col items-center">
                        <div className="items-center w-[40%]">
                          <Image src={logoimg} alt="logoimg" />
                        </div>
                        <h1 className="flex justify-center text-2xl font-semibold mb-1 text-[#1e336a]">
                          Forgot password ?
                        </h1>

                        <div className="mb-2 w-full">
                          <div className="mb-1 block text-sm font-medium text-gray-900">
                            Email/Mobile
                            <span className=" text-red-500"> * </span>
                          </div>
                          <input
                            type="text"
                            ref={emailOrMobileInputRef}
                            disabled={isEnabledMobileOrEmailInput}
                            placeholder="Enter Registred Email OR Mobile"
                            value={emailOrMobile}
                            onChange={handleEmailOrMobile}
                            className="bordercolor w-full p-2  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs"
                          />
                          <div className="text-red-500">
                            {/* {otpVerificationError} */}
                            {emailOrMobileError}
                          </div>
                        </div>

                        {isEnabledMobileOrEmailInput && (
                          <>
                            <div className="mb-2 w-full">
                              <div className="mb-1 block text-sm font-medium text-gray-900">
                                OTP
                                <span className=" text-red-500"> * </span>
                              </div>
                              <input
                                type="text"
                                placeholder="Enter OTP"
                                value={emailOrMobileOtp}
                                onChange={handleEmailMobileOtpChange}
                                className="bordercolor w-full p-2  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs"
                              />
                              <div className="text-red-500">
                                {/* {otpVerificationError} */}
                                {emailOrMobileOtpError}
                              </div>
                            </div>
                            <div className="mb-2 w-full">
                              <label className="mb-1 block text-sm font-medium text-gray-900">
                                New Password
                                <span className="text-red-500">*</span>
                              </label>
                              <div className="relative flex items-center mt-1 mb-2">
                                {/* <BsFileLock className="text-gray-400 absolute left-2" /> */}
                                <input
                                  minLength={8}
                                  maxLength={16}
                                  type={
                                    passwordType === "password"
                                      ? "password"
                                      : "text"
                                  }
                                  value={newPassword}
                                  placeholder="New Password"
                                  onChange={handleNewPasswordChange}
                                  className="bordercolor w-full p-2  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs"
                                />
                                {passwordType === "password" ? (
                                  <BsEyeSlash
                                    className="text-gray-400 cursor-pointer absolute right-2"
                                    onClick={passwordShowHide}
                                  />
                                ) : (
                                  <BsEye
                                    className="text-gray-400 cursor-pointer absolute right-2"
                                    onClick={passwordShowHide}
                                  />
                                )}
                              </div>
                              <div
                                id="newPasswordError"
                                className="text-red-500 text-sm"
                              >
                                {newPasswordError}
                              </div>
                            </div>

                            <div className="mb-2 w-full">
                              <label className="mb-1 block text-sm font-medium text-gray-900">
                                Confirm Password
                                <span className="text-red-500">*</span>
                              </label>
                              <div className="relative flex items-center mt-1 mb-3">
                                {/* <BsFileLock className="text-gray-400 absolute left-2" /> */}
                                <input
                                  minLength={8}
                                  maxLength={16}
                                  type={
                                    passwordType === "password"
                                      ? "password"
                                      : "text"
                                  }
                                  value={confirmNewPassword}
                                  placeholder="Confirm Password"
                                  onChange={handleConfirmPasswordChange}
                                  className="bordercolor w-full p-2  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs"
                                />
                                {passwordType === "password" ? (
                                  <BsEyeSlash
                                    className="text-gray-400 cursor-pointer absolute right-2"
                                    onClick={passwordShowHide}
                                  />
                                ) : (
                                  <BsEye
                                    className="text-gray-400 cursor-pointer absolute right-2"
                                    onClick={passwordShowHide}
                                  />
                                )}
                              </div>
                              <div
                                id="confirmNewPasswordError"
                                className="text-red-500 text-sm"
                              >
                                {confirmNewPasswordError}
                              </div>
                            </div>
                          </>
                        )}

                        <div className="flex items-center justify-between w-full">
                          <button
                            onClick={handleEmailORMobileOtpSubmit}
                            className="w-full px-6 py-2 bg-[#1e336a] text-white rounded-md hover:bg-[#1e336a] focus:outline-none"
                          >
                            Send OTP
                          </button>

                          {/* <button
                        onClick={() => setShowOtpPopup(false)}
                        className="absolute top-2 right-2 text-gray-500 cursor-pointer"
                      ></button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {logOutAllDeviceModal && (
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                  className="fixed inset-0 transition-opacity"
                  aria-hidden="true"
                >
                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                {showLoader && <Loader />}
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <button
                    onClick={() => setLogOutAllDeviceModal(false)}
                    type="button"
                    className="crossicon"
                    data-modal-hide="default-modal"
                  >
                    <svg
                      className="crossiconSvg"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                  </button>
                  <div className="bg-white p-5 flex flex-col items-center">
                    <div className="items-center w-[40%]">
                      <Image src={logoimg} alt="logoimg" />
                    </div>
                    <div className="text-2xl flex p-3 justify-start text-left font-semibold mb-1 text-[#1e336a]">
                      Log Out of All Devices.
                    </div>
                    <div className=" font-inter text-lg text-center">
                      {capitalizeFirstLetter(logOutAllDevice.message)}
                    </div>

                    <div className="flex items-center justify-center mt-5 w-full">
                      <button
                        onClick={handleLoginSubmit}
                        className="w-[40%] px-6 py-2 bg-[#1e336a] text-white rounded-md hover:bg-[#1e336a] focus:outline-none"
                      >
                        Log Out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
